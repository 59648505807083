import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
} from "antd";
import axios from "axios";

import { formatDate, formatDatetime } from "../shared/utils/dateTime";

const AddPaymentForm = (props) => {
  const { form, item = {}, onSubmit, onClose } = props;
  const { getFieldDecorator } = form;

  const [date, setDate] = useState();
  const [rates, setRates] = useState();
  const [currency, setCurrency] = useState("BGN");

  useEffect(() => {
    if (date) {
      axios.get(`/api/rates?date=${date}`).then((res) => {
        setRates(res.data);
      });
    }
  }, [date]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Drawer
      title={props.item ? "Редактирай плащане" : "Добави плащане"}
      width={320}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Дата на плащане">
              {getFieldDecorator("date", {
                onChange: (value) => {
                  setDate(formatDate(value));
                },
                rules: [{ required: true, message: "Моля въведете дата" }],
              })(<DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Валута">
              {getFieldDecorator("currency", {
                initialValue: "BGN",
                onChange: (e) => setCurrency(e.target.value),
                rules: [
                  { required: true, message: "Изберете валута на превода" },
                ],
              })(
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="BGN">в лева</Radio.Button>
                  <Radio.Button value="USD">в долара</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>

          {currency === "BGN" && (
            <Col span={24}>
              <Form.Item label={`Курс на долара`}>
                {getFieldDecorator("exchangeRate", {
                  initialValue:
                    rates?.historical?.selling || rates?.currency?.selling,
                  rules: [
                    { required: true, message: "Моля въведете курс на долара" },
                  ],
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Курс на долара"
                  />
                )}
                {rates && (
                  <i>
                    Курс към{" "}
                    {formatDatetime(
                      rates?.historical?.updatedAt || rates?.currency?.updatedAt
                    )}
                  </i>
                )}
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item label="Сума">
              {getFieldDecorator("amount", {
                initialValue: item.amount,
                rules: [{ required: true, message: "Моля въведете сума" }],
              })(
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={`В ${currency}`}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Реф #">
              {getFieldDecorator("ref", {
                initialValue: item.ref,
              })(
                <Input
                  style={{ width: "100%" }}
                  placeholder="Референтен номер"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Пояснение за плащане">
              {getFieldDecorator("note", {
                initialValue: item.note,
              })(<Input.TextArea rows={3} placeholder="Пояснение" />)}
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            Запази
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default Form.create()(AddPaymentForm);
