import './App.css';

import React from 'react';

import { ConfigProvider } from 'antd';
import bgBG from 'antd/es/locale/bg_BG';

import { Layout } from './components';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './provider/AuthProvider';
import { SeasonProvider } from './provider/SeasonProvider';
import Routes from './Routes';

function App() {
  return (
    <ConfigProvider locale={bgBG}>
      <ErrorBoundary>
        <AuthProvider>
          <SeasonProvider>
            <Layout>
              <Routes />
            </Layout>
          </SeasonProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
