import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  InputNumber,
  Select,
  DatePicker,
  Switch,
  Upload,
  Icon,
  message
} from "antd";
import { Loading } from "../components";
import { useScreenSize } from "../shared/hooks/useScreenSize";
import { momentDatetime } from "../shared/utils/dateTime";

const { Option } = Select;
const { Dragger } = Upload;

const EditTicketForm = props => {
  const [screenSize, isMobile] = useScreenSize();
  const [application, setApplicaiton] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { form, onSubmit, onClose, applicationId } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/api/applications/${applicationId}`).then(res => {
      const { ticket = {} } = res.data;
      setApplicaiton(ticket);
      setIsLoading(false);
    });
  }, [applicationId]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  const uploadProps = {
    name: "file",
    action: "/api/upload",
    accept: ".pdf",
    data: {
      applicationId: applicationId
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  return (
    <Drawer
      title="Самолетен билет"
      width={isMobile ? screenSize : 720}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Loading isLoading={isLoading}>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Авиокомпания">
                {getFieldDecorator("airline", {
                  initialValue: application.airline
                })(
                  <Select placeholder="Избери авиокомания">
                    {[
                      "Alitalia",
                      "Air France",
                      "KLM",
                      "Turkish Airlines",
                      "British Airways"
                    ].map(a => (
                      <Option key={a} value={a}>
                        {a}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Цена (авиокомпания)">
                {getFieldDecorator("airlinePrice", {
                  initialValue: application.airlinePrice
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Цена в лева"
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Цена (с такси)">
                {getFieldDecorator("finalPrice", {
                  initialValue: application.finalPrice
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Цена в лева"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Дата на заминаване">
                {getFieldDecorator("inboundDate", {
                  initialValue: momentDatetime(application.inboundDate)
                })(
                  <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Дата на връщане">
                {getFieldDecorator("outboundDate", {
                  initialValue: momentDatetime(application.outboundDate)
                })(
                  <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Полет">
                {getFieldDecorator("file", {
                  initialValue: application.file
                    ? [
                        {
                          uid: 1,
                          url: `/api/upload/${applicationId}/${application.file.name}`,
                          ...application.file
                        }
                      ]
                    : [],
                  valuePropName: "fileList",
                  getValueFromEvent: e => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    if (e.fileList.length > 1) {
                      e.fileList.shift();
                    }
                    return e && e.fileList;
                  }
                })(
                  <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Натисни или пусни файл за да качиш
                    </p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Платен от студент">
                {getFieldDecorator("paid", {
                  initialValue: application.paid,
                  valuePropName: "checked"
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Издаден от авиокомапния">
                {getFieldDecorator("issued", {
                  initialValue: application.issued,
                  valuePropName: "checked"
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Платен от нас">
                {getFieldDecorator("paidAirline", {
                  initialValue: application.paidAirline,
                  valuePropName: "checked"
                })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Нуждае се от трансфер">
                {getFieldDecorator("needsTransfer", {
                  initialValue: application.needsTransfer,
                  valuePropName: "checked"
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Трансфер е потвърден">
                {getFieldDecorator("confirmedTransfer", {
                  initialValue: application.confirmedTransfer,
                  valuePropName: "checked"
                })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button type="primary" htmlType="submit">
              Запази
            </Button>
          </div>
        </Form>
      </Loading>
    </Drawer>
  );
};

export default Form.create()(EditTicketForm);
