import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Drawer,
  Form,
  Row,
} from 'antd';
import axios from 'axios';

import { CURRENT_SWT_SEASON } from '../shared/constants';
import { useScreenSize } from '../shared/hooks/useScreenSize';
import Loading from './Loading';

const CopyApplication = (props) => {
  const [screenSize, isMobile] = useScreenSize();

  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState({});

  const { onClose } = props;

  useEffect(() => {
    axios.get(`/api/applications/${props.applicationId}`).then((res) => {
      setApplication(res.data);
      setIsLoading(false);
    });
  }, [props.applicationId]);

  const handleSubmit = () => {
    const values = {
      ...application,
      payments: [],
      embassy: {},
      status: "initial",
      employer: {},
      swtSeason: CURRENT_SWT_SEASON,
    };
    delete values._id;

    axios.post("/api/applications", values).then((res) => {
      onClose();
    });
  };

  return (
    <Drawer
      title={"Презапиши студент"}
      width={isMobile ? screenSize : 320}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Loading isLoading={isLoading}>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              Презапиши <strong>{application.fullname}</strong> за{" "}
              <strong>Сезон {CURRENT_SWT_SEASON}?</strong>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button type="primary" onClick={handleSubmit}>
              Запази
            </Button>
          </div>
        </Form>
      </Loading>
    </Drawer>
  );
};

export default CopyApplication;
