import React, { useEffect, useState } from "react";

import { Col, Drawer, Row, Typography } from "antd";
import axios from "axios";

import { Loading } from "../components";
import { useScreenSize } from "../shared/hooks/useScreenSize";
import { formatDate, formatDatetimeBasic } from "../shared/utils/dateTime";

const ViewApplication = ({ applicationId, onClose, onDelete }) => {
  const [isLoading, setIsloading] = useState(true);
  const [application, setApplication] = useState({});
  const [screenSize, isMobile] = useScreenSize();

  useEffect(() => {
    axios.get(`/api/applications/${applicationId}`).then((res) => {
      setApplication(res.data);
      setIsloading(false);
    });
  }, [applicationId]);

  const Copyable = ({ children }) => {
    if (children)
      return <Typography.Paragraph copyable>{children}</Typography.Paragraph>;
    return null;
  };

  return (
    <Drawer
      title={application.fullname}
      width={isMobile ? screenSize : 500}
      placement="right"
      onClose={onClose}
      visible={true}
    >
      <Loading isLoading={isLoading}>
        <Row>
          <Col md={8}>
            <strong>DS2019:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{application.ds2019}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>DS160:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{application.embassy?.ds160}</Copyable>
            <Copyable>{application.embassy?.ds160pw}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>UID:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{application.embassy?.uuid}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>DOB:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{formatDate(application.dob)}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>Passport:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{application.passport}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>Issuance Date:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{formatDate(application.passportValidFrom)}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>Expiration Date:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{formatDate(application.passportValidTill)}</Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>Interview Date:</strong>
          </Col>
          <Col md={16}>
            <Copyable>
              {formatDatetimeBasic(application.embassy?.interviewDate)}
            </Copyable>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <strong>Платежно:</strong>
          </Col>
          <Col md={16}>
            <Copyable>{application.embassy?.paymentFeeNum}</Copyable>
          </Col>
        </Row>
      </Loading>
    </Drawer>
  );
};

export default ViewApplication;
