import React, { useState } from "react";
import { Card, Col, Row, InputNumber, Typography } from "antd";

const buyCurrency = (value, rate) => {
  return value * rate;
};

const sellCurrency = (value, rate) => {
  return value / rate;
};

const tryConvert = (value, rate, converter) => {
  const input = parseFloat(value);
  if (Number.isNaN(input)) {
    return "";
  }
  const output = converter(input, rate);
  const rounded = Math.round(output * 100) / 100;
  return rounded.toString();
};

const ExchangeDetails = ({ fromCurrency, rate, bgn, usd }) => {
  return (
    <i>
      {fromCurrency === "bgn" && `${bgn} лв. / ${rate} = ${usd} $`}
      {fromCurrency === "usd" && `${usd} $ * ${rate} = ${bgn} лв.`}
    </i>
  );
};

const ExchangeCalculator = ({
  title,
  bordered,
  defaultCurrency,
  defaultValue,
  rate,
}) => {
  const [currency, setCurrency] = useState(defaultCurrency);
  const [currencyValue, setCurrencyValue] = useState(defaultValue);

  const onChangeUSD = (value) => {
    setCurrency("usd");
    setCurrencyValue(value);
  };

  const onChangeBGN = (value) => {
    setCurrency("bgn");
    setCurrencyValue(value);
  };

  const usd =
    currency === "bgn"
      ? tryConvert(currencyValue, rate, sellCurrency)
      : currencyValue;
  const bgn =
    currency === "usd"
      ? tryConvert(currencyValue, rate, buyCurrency)
      : currencyValue;

  const currencies = {
    USD: (
      <Col span={10} style={{ textAlign: "center" }}>
        <InputNumber
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          step={10}
          value={usd}
          onChange={onChangeUSD}
        />
      </Col>
    ),
    BGN: (
      <Col span={10} style={{ textAlign: "center" }}>
        <InputNumber
          formatter={(value) =>
            `${value} лв`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          precision={2}
          step={10}
          value={bgn}
          onChange={onChangeBGN}
        />
      </Col>
    ),
  };

  return (
    <Card title={title} bordered={!!bordered}>
      <Row>
        {defaultCurrency === "usd" ? currencies.USD : currencies.BGN}
        <Col span={4} style={{ textAlign: "center" }}>
          <Typography.Title level={4}>=</Typography.Title>
        </Col>
        {defaultCurrency === "usd" ? currencies.BGN : currencies.USD}
      </Row>
      <Row style={{ textAlign: "center" }}>
        <ExchangeDetails
          fromCurrency={currency}
          step={10}
          bgn={bgn}
          usd={usd}
          rate={rate}
        />
      </Row>
    </Card>
  );
};

export default ExchangeCalculator;
