import './Layout.css';

import React, {
  Fragment,
  useContext,
  useState,
} from 'react';

import {
  Drawer,
  Layout,
} from 'antd';

import {
  Breadcrumbs,
  Header,
  Sider,
} from '../../components';
import {
  AuthContext,
  SeasonContext,
} from '../../context';
import { useScreenSize } from '../../shared/hooks/useScreenSize';

const { Content, Footer } = Layout;

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [screenSize, isMobile] = useScreenSize();

  const user = useContext(AuthContext);
  const season = useContext(SeasonContext);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const siderProps = {
    collapsed,
    onCollapse,
  };

  const headerProps = {
    collapsed,
    onCollapse,
  };

  return (
    <Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        {user.isAuthenticated ? (
          isMobile ? (
            <Drawer
              className="ant-drawer-slider"
              maskClosable
              closable={false}
              onClose={onCollapse}
              visible={!collapsed}
              placement="left"
              width={isMobile ? screenSize / 2 : 200}
              style={{
                padding: 0,
                height: "100vh",
              }}
            >
              <Sider {...siderProps} />
            </Drawer>
          ) : (
            <Sider {...siderProps} />
          )
        ) : (
          ""
        )}
        <Layout>
          {user.isAuthenticated && (
            <Header {...headerProps} user={user} season={season} />
          )}

          <Content style={{ margin: "0 16px" }}>
            {user.isAuthenticated && <Breadcrumbs />}
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
          {!isMobile && (
            <Footer style={{ textAlign: "center" }}>
              Happy World - Work & Travel
            </Footer>
          )}
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MainLayout;
