import React from 'react';

import { Select } from 'antd';

const { Option } = Select;

const StudentRemoteSelect = ({ students, onChange }) => {
  return (
    <Select
      showSearch
      onChange={onChange}
      placeholder="Избери студент"
      style={{ width: 200 }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children
          .join(" ")
          .replace(/\s\s+/g, " ")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
    >
      {students &&
        students.map((student) => {
          return (
            <Option key={student.id} id={student.id} value={student.id}>
              {student.firstname} {student.lastname}
            </Option>
          );
        })}
    </Select>
  );
};

export { StudentRemoteSelect };
