import React, { useState, useEffect } from "react";
import AddUniversity from "./AddUniversity";
import axios from "axios";

const EditUniversity = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [university, setUniversity] = useState();

  useEffect(() => {
    axios.get(`/api/universities/${props.universityId}`).then((res) => {
      setUniversity(res.data);
      setIsLoading(false);
    });
  }, [props.universityId]);

  return <AddUniversity isLoading={isLoading} item={university} {...props} />;
};

export default EditUniversity;
