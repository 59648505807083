import React, { Fragment, useEffect, useReducer } from "react";

import { Card, Col, Icon, Row } from "antd";
import axios from "axios";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";

import { AddUniversity, EditUniversity, Filter } from "../components";

const { Meta } = Card;

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        universities: action.payload,
        isLoading: false,
      };
    default:
      throw new Error(`Invalid action type ${action.type}`);
  }
};

const fetchUniversities = (dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios.get("/api/universities").then((res) => {
    dispatch({ type: "FETCH_SUCCESS", payload: res.data });
  });
};

const Universities = () => {
  const [{ universities }, dispatch] = useReducer(fetchReducer, {
    applications: [],
    isLoading: false,
  });

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchUniversities(dispatch);
  }, []);

  const onCreate = (values) => {
    axios.post("/api/universities", values).then((res) => {
      fetchUniversities(dispatch);
      history.push(match.url);
    });
  };

  const onEdit = (universityId, values) => {
    axios.put(`/api/universities/${universityId}`, values).then((res) => {
      fetchUniversities(dispatch);
      history.push(match.url);
    });
  };

  const sortByCityAndName = (a, b) => {
    // First, compare by city
    if (a.city < b.city) return -1;
    if (a.city > b.city) return 1;

    // If cities are the same, compare by name
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    // If both city and name are the same, return 0 (no sorting)
    return 0;
  };

  return (
    <Fragment>
      <Filter
        placeholder="Филтрирай университет"
        onFilterChange={(value) => console.log(value)}
        onAdd={() => history.push(`${match.url}/create`)}
      />

      <Row gutter={[16, 24]}>
        {universities &&
          universities
            // .sort((a, b) => a.city.localeCompare(b.city))
            .sort(sortByCityAndName)
            .map((u) => (
              <Col xs={24} sm={12} md={4} key={u._id}>
                <Card
                  cover={
                    <img
                      style={{
                        maxHeight: "80px",
                        width: "auto",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      alt={u.name}
                      src={`/api/upload/logos/${u.logo?.name}`}
                    />
                  }
                  actions={[
                    <Link to={`/universities/edit/${u._id}`}>
                      <Icon type="edit" key="edit" />
                    </Link>,
                  ]}
                >
                  <Meta title={u.name} description={u.city} />
                </Card>
              </Col>
            ))}
      </Row>

      <Route
        exact
        path={`${match.path}/create`}
        render={(routeProps) => (
          <AddUniversity
            onClose={() => history.push(match.url)}
            onSubmit={onCreate}
          />
        )}
      />

      <Route
        exact
        path={`${match.path}/edit/:universityId`}
        render={(routeProps) => (
          <EditUniversity
            universityId={routeProps.match.params.universityId}
            onSubmit={(values) =>
              onEdit(routeProps.match.params.universityId, values)
            }
            onClose={() => history.push(match.url)}
          />
        )}
      />
    </Fragment>
  );
};

export default Universities;
