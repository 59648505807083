import React from 'react';

import { useParams } from 'react-router-dom';

const SignUp = () => {
  const { hash } = useParams();

  return <div>Hash: {hash}</div>;
};

export default SignUp;
