import { useState, useLayoutEffect } from "react";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState([
    window.innerWidth,
    window.innerWidth <= 480
  ]);

  useLayoutEffect(() => {
    const onResize = () => {
      setScreenSize([window.innerWidth, window.innerWidth <= 480]);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return screenSize;
};
