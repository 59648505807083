import React from "react";
import { Card, Col, Row, Statistic } from "antd";

const ExchangeRates = (props) => {
  return (
    <Card title={props.title} bordered={!!props.bordered}>
      <Row gutter={16}>
        {props.stats.map((item) => {
          return (
            <Col key={"key-" + item.title} span={12}>
              <Statistic title={item.title} value={item.value} />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default ExchangeRates;
