import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Col,
  Divider,
  Icon,
  Row,
} from 'antd';
import axios from 'axios';
import { size } from 'lodash';
import {
  Link,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import {
  AddApplication,
  BooleanIcon,
  CopyApplication,
  EditApplication,
  ExportXLSX,
  Filter,
  ToggableTable,
  ToggleColumns,
  ViewApplication,
  ViewApplicationProfile,
  ViewPayments,
} from '../components';
import { CURRENT_SWT_SEASON } from '../shared/constants';
import { useSeason } from '../shared/hooks/useSeason';

const initialColumns = [
  {
    title: "Име",
    dataIndex: "fullname",
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
  },
  {
    title: "Паспорт",
    dataIndex: "passport",
    visible: false,
  },
  {
    title: "DS2019",
    dataIndex: "ds2019",
    sorter: (a, b) => {
      const aDS2019 = a.ds2019 || "";
      const bDS2019 = b.ds2019 || "";
      return aDS2019.localeCompare(bDS2019);
    },
    visible: false,
  },
  {
    title: "Цена",
    dataIndex: "price",
    render: (text) => (text ? `${text}$` : ""),
    sorter: (a, b) => (a.price || 0) - (b.price || 0),
  },
  {
    title: "Остава",
    dataIndex: "priceLeft",
    render: (text, rec) => {
      if (rec.status === "cancelled") {
        return (
          <>
            <strong>Платено: </strong> {rec.totalPaid.toFixed(0)}$
            <br />
            <strong>Възстановено: </strong> {rec.totalRefunded.toFixed(0)}$
          </>
        );
      }
      return text ? `${text.toFixed(0)}$` : "";
    },
    sorter: (a, b) => (a.priceLeft || 0) - (b.priceLeft || 0),
  },
  {
    title: "Спонсор",
    dataIndex: "sponsor",
    sorter: (a, b) => size(a.sponsor) - size(b.sponsor),
  },
  {
    title: "Ел. поща",
    dataIndex: "email",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
  },
  {
    title: "Застр.",
    dataIndex: "insurance",
    render: (text, rec) => {
      return (
        <>
          <BooleanIcon checked={rec.insurance} />
          {rec.insurance && (
            <>
              {" / "}
              <BooleanIcon
                checked={rec.insuranceEnrolled}
                colored={!rec.insuranceEnrolled}
              />
            </>
          )}
        </>
      );
    },
  },
  {
    title: "Кредит",
    dataIndex: "credit",
    render: (text, rec) => <BooleanIcon checked={rec.credit} />,
  },
  {
    title: "Action",
    dataIndex: "",
    key: "action",
    width: "124px",
    render: (text, rec) => {
      const swtSeason =
        JSON.parse(localStorage.getItem("swtSeason")) || CURRENT_SWT_SEASON;
      const showCopy = CURRENT_SWT_SEASON !== swtSeason;

      return (
        <Fragment>
          <Link to={`/applications/view/${rec._id}`}>
            <Icon type="user" />
          </Link>
          <Divider type="vertical" />
          <Link to={`/applications/payments/${rec._id}`}>
            <Icon type="bank" />
          </Link>
          {showCopy && (
            <>
              <Divider type="vertical" />
              <Link to={`/applications/copy/${rec._id}`}>
                <Icon type="reload" />
              </Link>
            </>
          )}
          <Divider type="vertical" />
          <Link to={`/applications/edit/${rec._id}`}>
            <Icon type="form" />
          </Link>
        </Fragment>
      );
    },
  },
];

const rowTransform = (rec) => {
  return {
    "Дата на профил": rec.createdAt,
    Име: rec.fullname,
    "Ел. поща": rec.email,
    "Цена програма": rec.price,
    "Такса севис": 40,
    Застраховка: rec.insurance ? "да" : "не",
    "Цена на билет": rec.ticket?.finalPrice,
    "Сума дължима към USA": rec.sponsor,
  };
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        applications: action.payload,
        xlsxApplications: action.payload.map(rowTransform),
        isLoading: false,
      };
    case "FETCH_UNIVERSITIES":
      return {
        ...state,
        universities: action.payload,
        isLoading: false,
      };
    default:
      throw new Error(`Invalid action type ${action.type}`);
  }
};

const fetchApplications = (query = "", swtSeason, dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios
    .get(`/api/applications?search=${query}&swtSeason=${swtSeason}`)
    .then((res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res.data });
    });
};

const fetchUniversities = (dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios.get(`/api/universities`).then((res) => {
    dispatch({ type: "FETCH_UNIVERSITIES", payload: res.data });
  });
};

const Applications = (props) => {
  const { swtSeason } = useSeason();

  const [
    { isLoading, applications, xlsxApplications, universities },
    dispatch,
  ] = useReducer(fetchReducer, {
    applications: [],
    universities: [],
    xlsxApplications: [],
    isLoading: false,
  });
  const [query, setQuery] = useState();
  const [columns, setColumns] = useState(initialColumns);

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchApplications(query, swtSeason, dispatch);
  }, [query, swtSeason]);

  useEffect(() => {
    fetchUniversities(dispatch);
  }, []);

  const onEdit = (applicationId, values) => {
    axios.put(`/api/applications/${applicationId}`, values).then((res) => {
      fetchApplications(query, swtSeason, dispatch);
      history.push(match.url);
    });
  };

  const onCreate = (values) => {
    axios.post("/api/applications", values).then((res) => {
      fetchApplications(query, swtSeason, dispatch);
      history.push(match.url);
    });
  };

  const onDelete = () => {
    fetchApplications(query, swtSeason, dispatch);
    history.push(match.url);
  };

  const onFilterChange = (value) => {
    setQuery(value);
  };

  return (
    <Fragment>
      <Filter
        onFilterChange={onFilterChange}
        onAdd={() => history.push(`${match.url}/create`)}
      >
        <ToggleColumns columns={columns} setColumns={setColumns} />
        <ExportXLSX
          csvData={xlsxApplications}
          fileName="Overview"
          tabName=""
        />{" "}
      </Filter>
      <ToggableTable
        scroll={{ x: "max-content" }}
        loading={isLoading}
        dataSource={applications}
        columns={columns}
        expandedRowRender={(application) => (
          <div style={{ margin: "20px 0 0 0" }}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={16}>
                <ViewApplicationProfile
                  application={application}
                  hideEmployer={true}
                  hideDelete={true}
                />
              </Col>
            </Row>
          </div>
        )}
        rowClassName={(record) => {
          if (record.status === "cancelled") return "ant-table-row-failure";
          if (!record.depositPaid) return "ant-table-row-warning";
          return "";
        }}
        size="middle"
        rowKey="_id"
        pagination={false}
        footer={(currentData) => `Общо: ${currentData.length}`}
      />
      <Route
        exact
        path={`${match.path}/view/:applicationId`}
        render={(routeProps) => (
          <ViewApplication
            applicationId={routeProps.match.params.applicationId}
            onClose={() => history.push(match.url)}
            onDelete={onDelete}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/payments/:applicationId`}
        render={(routeProps) => (
          <ViewPayments
            applicationId={routeProps.match.params.applicationId}
            onClose={() => history.push(match.url)}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/create`}
        render={(routeProps) => (
          <AddApplication
            universities={universities}
            onClose={() => history.push(match.url)}
            onSubmit={onCreate}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/copy/:applicationId`}
        render={(routeProps) => (
          <CopyApplication
            applicationId={routeProps.match.params.applicationId}
            onClose={() => history.push(match.url)}
            onDelete={onDelete}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/edit/:applicationId`}
        render={(routeProps) => (
          <EditApplication
            universities={universities}
            applicationId={routeProps.match.params.applicationId}
            onSubmit={(values) =>
              onEdit(routeProps.match.params.applicationId, values)
            }
            onClose={() => history.push(match.url)}
          />
        )}
      />
    </Fragment>
  );
};

export default Applications;
