import React, {
  useEffect,
  useState,
} from 'react';

import { SeasonContext } from '../context/SeasonContext';
import { CURRENT_SWT_SEASON } from '../shared/constants';

export const SeasonProvider = (props) => {
  const initialSeason =
    JSON.parse(localStorage.getItem("swtSeason")) || CURRENT_SWT_SEASON;

  const [swtSeason, setSwtSeason] = useState(initialSeason);

  useEffect(() => {
    localStorage.setItem("swtSeason", swtSeason);
  }, [swtSeason]);

  return (
    <SeasonContext.Provider value={{ swtSeason, setSwtSeason }}>
      {props.children}
    </SeasonContext.Provider>
  );
};
