import React from "react";

import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import {
  Applications,
  Appointments,
  Certificates,
  Dashboard,
  Embassy,
  Login,
  Logout,
  NotFound,
  SignUp,
  Tickets,
  Universities,
} from "./pages";
import Agreements from "./pages/Agreements";

const Routes = (props) => {
  return (
    <Switch>
      {/* Root (handles first login logic and user type redirect) */}
      {/* <PrivateRoute exact path="/" /> */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route path="/signup/:hash" component={SignUp} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute path="/applications" component={Applications} />
      <PrivateRoute path="/tickets" component={Tickets} />
      <PrivateRoute path="/embassy" component={Embassy} />
      <PrivateRoute path="/appointments" component={Appointments} />
      <PrivateRoute path="/certificates" component={Certificates} />
      <PrivateRoute path="/agreements" component={Agreements} />
      <PrivateRoute path="/universities" component={Universities} />
      <PrivateRoute path="" component={NotFound} />
      {/* <PrivateRoute exact path="/files" component={FilesBrowser} /> */}
    </Switch>
  );
};

export default Routes;
