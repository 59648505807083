import React, { Fragment } from "react";

import { Avatar, Menu as MainMenu } from "antd";

import avatar from "../../logo.png";

const { SubMenu } = MainMenu;

const Menu = ({ user, season }) => {
  const { swtSeason, setSwtSeason } = season;

  return (
    <MainMenu key="user" mode="horizontal">
      <SubMenu
        title={
          <Fragment>
            <span style={{ color: "#999", marginRight: 4 }}>Сезон</span>
            <span>{swtSeason}</span>
          </Fragment>
        }
        style={{ float: "left" }}
      >
        {[2022, 2023, 2024, 2025].map((swtYear) => (
          <MainMenu.Item key={swtYear} onClick={() => setSwtSeason(swtYear)}>
            {swtYear}
          </MainMenu.Item>
        ))}
      </SubMenu>
      <SubMenu
        title={
          <Fragment>
            <span style={{ color: "#999", marginRight: 4 }}>Здравей</span>
            <span>{user.credentials.username}</span>
            <Avatar style={{ marginLeft: 8 }} src={avatar} />
          </Fragment>
        }
        style={{ float: "right" }}
      >
        <MainMenu.Item key="SignOut" onClick={user.signout}>
          Изход
        </MainMenu.Item>
      </SubMenu>
    </MainMenu>
  );
};

export default Menu;
