import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Divider,
  Icon,
  Table,
} from 'antd';
import axios from 'axios';
import {
  Link,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import {
  BooleanIcon,
  EditTicket,
} from '../components';
import Filter from '../components/Filter';
import { useSeason } from '../shared/hooks/useSeason';
import { formatDate } from '../shared/utils/dateTime';

const columns = [
  {
    title: "Студент",
    dataIndex: "fullname",
  },
  {
    title: "Авиокомпания",
    dataIndex: "ticket.airline",
  },
  {
    title: "Заминаване",
    dataIndex: "ticket.inboundDate",
    render: (text, rec) => {
      const { ticket = {} } = rec;
      return formatDate(ticket.inboundDate);
    },
    sorter: (a, b) => {
      const ae = a.ticket ? new Date(a.ticket.inboundDate) : 0;
      const be = b.ticket ? new Date(b.ticket.inboundDate) : 0;
      return ae - be;
    },
  },
  {
    title: "Връщане",
    dataIndex: "ticket.outboundDate",
    render: (text, rec) => {
      const { ticket = {} } = rec;
      return formatDate(ticket.outboundDate);
    },
    sorter: (a, b) => {
      const ae = a.ticket ? new Date(a.ticket.outboundDate) : 0;
      const be = b.ticket ? new Date(b.ticket.outboundDate) : 0;
      return ae - be;
    },
  },
  {
    title: "Цена",
    dataIndex: "ticket.airlinePrice",
  },
  {
    title: "Платен от студент",
    dataIndex: "ticket.paid",
    render: (text, rec) => <BooleanIcon checked={text} colored={true} />,
  },
  {
    title: "Издаден",
    dataIndex: "ticket.issued",
    render: (text, rec) => <BooleanIcon checked={text} colored={true} />,
  },
  { title: "Цена", dataIndex: "ticket.finalPrice" },
  {
    title: "Платен от нас",
    dataIndex: "ticket.paidAirline",
    render: (text, rec) => <BooleanIcon checked={text} colored={true} />,
  },
  {
    title: "Трансфер",
    dataIndex: "ticket.needsTransfer",
    render: (text, rec) => <BooleanIcon checked={text} colored={true} />,
  },
  {
    title: "Трансфер е потвърден",
    dataIndex: "ticket.confirmedTransfer",
    render: (text, rec) => <BooleanIcon checked={text} colored={true} />,
  },
  {
    title: "Actions",
    dataIndex: "",
    render: (text, rec) => (
      <Fragment>
        <Link to={`/tickets/edit/${rec._id}`}>
          <Icon type="form" />
        </Link>
        {rec.ticket && rec.ticket.file ? (
          <Fragment>
            <Divider type="vertical" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/api/upload/${rec._id}/${rec.ticket.file.name}`}
            >
              <Icon type="file-pdf" />
            </a>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    ),
  },
];

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        tickets: action.payload,
        isLoading: false,
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const fetchTickets = (query = "", swtSeason, dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios
    .get(`/api/applications?search=${query}&swtSeason=${swtSeason}`)
    .then((res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res.data });
    });
};

const Tickets = (props) => {
  const { swtSeason } = useSeason();
  const [{ tickets, isLoading }, dispatch] = useReducer(fetchReducer, {
    isLoading: false,
    tickets: [],
  });
  const [query, setQuery] = useState();

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchTickets(query, swtSeason, dispatch);
  }, [query, swtSeason]);

  const onFilterChange = (value) => {
    setQuery(value);
  };

  const onEdit = (paymentId, values) => {
    axios
      .put(`/api/applications/${paymentId}`, {
        ticket: values,
      })
      .then((res) => {
        fetchTickets(query, swtSeason, dispatch);
        history.push(match.url);
      });
  };

  return (
    <Fragment>
      <Filter onFilterChange={onFilterChange} />
      <Table
        scroll={{ x: "max-content" }}
        dataSource={tickets}
        loading={isLoading}
        columns={columns}
        size="middle"
        rowKey="_id"
        pagination={false}
        footer={(currentData) => `Общо: ${currentData.length}`}
      />

      <Route
        exact
        path={`${match.path}/edit/:applicationId`}
        render={(routeProps) => (
          <EditTicket
            onClose={() => history.push(match.url)}
            applicationId={routeProps.match.params.applicationId}
            onSubmit={(values) =>
              onEdit(routeProps.match.params.applicationId, values)
            }
          />
        )}
      />
    </Fragment>
  );
};

export default Tickets;
