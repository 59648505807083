import React from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";

const ExportXLSX = ({ csvData, tabName = "Interviews", fileName }) => {
  // const fileType =
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToXLSX = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, tabName);
    XLSX.writeFile(wb, fileName + fileExtension);
  };

  return (
    <Button
      type="primary"
      icon="download"
      onClick={(e) => exportToXLSX(csvData, fileName)}
    >
      Export
    </Button>
  );
};

export default ExportXLSX;
