import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Switch,
  Tag,
} from "antd";
import axios from "axios";

import { useScreenSize } from "../shared/hooks/useScreenSize";
import { momentDatetime } from "../shared/utils/dateTime";
import Loading from "./Loading";

const EditEmbassyForm = (props) => {
  const [application, setApplication] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [screenSize, isMobile] = useScreenSize();

  const { form, onSubmit, onClose, applicationId } = props;
  const { getFieldDecorator } = form;

  const { embassy = {} } = application;

  useEffect(() => {
    axios.get(`/api/applications/${applicationId}`).then((res) => {
      setApplication(res.data);
      setIsLoading(false);
    });
  }, [applicationId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Drawer
      title={
        <>
          Докуменети за посолство{" "}
          <Tag color="magenta">{application.fullname}</Tag>
        </>
      }
      width={isMobile ? screenSize : 640}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Loading isLoading={isLoading}>
        <Form onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="DS2019">
                {getFieldDecorator("ds2019", {
                  initialValue: application.ds2019,
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={16}>
              <Form.Item label="DS160 / парола">
                {getFieldDecorator("ds160", {
                  initialValue: embassy.ds160,
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Дата и час на интерво">
                {getFieldDecorator("interviewDate", {
                  initialValue: momentDatetime(embassy.interviewDate),
                })(
                  <DatePicker
                    showTime={{
                      format: "HH:mm",
                    }}
                    format="DD/MM/YYYY HH:mm"
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Payment #">
                {getFieldDecorator("paymentFeeNum", {
                  initialValue: embassy.paymentFeeNum,
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="UID">
                {getFieldDecorator("uuid", {
                  initialValue: embassy.uuid,
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Паспорт">
                {getFieldDecorator("hasPassport", {
                  initialValue: embassy.hasPassport,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
              <Form.Item label="Книжка/справка">
                {getFieldDecorator("hasStudentBook", {
                  initialValue: embassy.hasStudentBook,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
              <Form.Item label="Sevis">
                {getFieldDecorator("hasSevis", {
                  initialValue: embassy.hasSevis,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Снимка (+ хартиена)">
                {getFieldDecorator("hasPhoto", {
                  initialValue: embassy.hasPhoto,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>

              <Form.Item label="Job offer">
                {getFieldDecorator("hasJobOffer", {
                  initialValue: embassy.hasJobOffer,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>

              <Form.Item label="DS160 Confirmation">
                {getFieldDecorator("hasConfirmation", {
                  initialValue: embassy.hasConfirmation,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={8}>
              <Form.Item label="Уверение">
                {getFieldDecorator("hasStudentStatus", {
                  initialValue: embassy.hasStudentStatus,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>

              <Form.Item label="Платежно">
                {getFieldDecorator("hasPaid", {
                  initialValue: embassy.hasPaid,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>

              <Form.Item label="Взел виза">
                {getFieldDecorator("visaApproved", {
                  initialValue: embassy.visaApproved,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit">
              Запази
            </Button>
          </div>
        </Form>
      </Loading>
    </Drawer>
  );
};

export default Form.create()(EditEmbassyForm);
