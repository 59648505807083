import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import { Icon, Button, Menu, Checkbox, Dropdown } from "antd";

const ToggleColumns = ({ columns, setColumns }) => {
  const menuRef = useRef();
  const buttonRef = useRef();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleClick = (e) => {
      const realMenuRef = ReactDOM.findDOMNode(menuRef.current);
      const realButtonRef = ReactDOM.findDOMNode(buttonRef.current);

      if (
        realMenuRef &&
        !realMenuRef.contains(e.target) &&
        realButtonRef &&
        !realButtonRef.contains(e.target)
      ) {
        setVisible(false);
      }
    };

    window.addEventListener("click", handleClick, { capture: true });

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [visible]);

  const onChange = (e) => {
    const updatedColumns = [...columns];
    const column = updatedColumns.find((c) => c.dataIndex === e.target.id);
    column.visible = e.target.checked;
    setColumns(updatedColumns);
  };

  const menu = (
    <Menu ref={menuRef}>
      {columns
        .filter((c) => !!c.dataIndex)
        .map(({ title, visible, dataIndex }) => {
          return (
            <Menu.Item key={title}>
              {visible !== false ? (
                <Checkbox id={dataIndex} onChange={onChange} defaultChecked>
                  {title}
                </Checkbox>
              ) : (
                <Checkbox id={dataIndex} onChange={onChange}>
                  {title}
                </Checkbox>
              )}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <Dropdown
      ref={buttonRef}
      overlay={menu}
      visible={visible}
      handleVisibleChange={(visible) => {
        setVisible(visible);
      }}
      trigger={["click"]}
      onClick={() => setVisible(!visible)}
    >
      <Button className="margin-right" style={{ marginRight: "4px" }}>
        <Icon type="table" />
      </Button>
    </Dropdown>
  );
};

export default ToggleColumns;
