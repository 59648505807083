import React from "react";

import { Icon, Layout, Menu } from "antd";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Sider = (props) => {
  const { collapsed, onCollapse } = props;

  const menuItems = [
    {
      text: "Начало",
      url: "/",
      iconType: "home",
    },
    {
      text: "Студенти",
      url: "/applications",
      iconType: "team",
      subItems: [{ text: "2020", url: "/ " }],
    },
    {
      text: "Посолство",
      url: "/embassy",
      iconType: "reconciliation",
    },
    {
      text: "Самолетни билети",
      url: "/tickets",
      iconType: "rocket",
    },
    {
      text: "Университети",
      url: "/universities",
      iconType: "idcard",
    },
    {
      text: "Удостоврения",
      url: "/certificates",
      iconType: "file-pdf",
    },
    {
      text: "Договори",
      url: "/agreements",
      iconType: "read",
    },
    {
      text: "Календар",
      url: "/appointments",
      iconType: "calendar",
    },
  ];

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{
        padding: 0,
        minHeight: "100vh",
      }}
    >
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        {menuItems.map((item, i) => (
          <Menu.Item key={"nav" + i}>
            <NavLink to={item.url}>
              <Icon type={item.iconType} />
              <span>{item.text}</span>
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  );
};

Sider.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
};

export default Sider;
