import React from 'react'
import { Table } from "antd";

const ToggableTable = ({ columns, ...props }) => {
  const visibleColumns = columns.filter(c => c.visible !== false);

  return (
    <Table
      columns={visibleColumns}
      {...props}
    />
  )
}

export default ToggableTable
