import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Breadcrumb } from "antd";

const Breadcrumbs = ({ breadcrumbs }) => (
  <Breadcrumb style={{ margin: "16px 0" }}>
    {breadcrumbs.map(({ breadcrumb, match }, index) => (
      <Breadcrumb.Item key={match.url}>
        <Link to={match.url || ""}>{breadcrumb}</Link>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default withBreadcrumbs()(Breadcrumbs);
