import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { Loading, ViewApplicationProfile } from "../components";
import { useScreenSize } from "../shared/hooks/useScreenSize";
import axios from "axios";

const ViewApplication = ({ applicationId, onClose, onDelete }) => {
  const [isLoading, setIsloading] = useState(true);
  const [application, setApplication] = useState({});
  const [screenSize, isMobile] = useScreenSize();

  useEffect(() => {
    axios.get(`/api/applications/${applicationId}`).then((res) => {
      setApplication(res.data);
      setIsloading(false);
    });
  }, [applicationId]);

  return (
    <Drawer
      title={application.fullname}
      width={isMobile ? screenSize : 720}
      placement="right"
      onClose={onClose}
      visible={true}
    >
      <Loading isLoading={isLoading}>
        <ViewApplicationProfile application={application} onDelete={onDelete} />
      </Loading>
    </Drawer>
  );
};

export default ViewApplication;
