export const sorter = (funcs) => {
  return function (a, b) {
    for (const func of funcs) {
      const result = func(a, b);
      if (result !== 0) {
        return result;
      }
    }
  };
};
