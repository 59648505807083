import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/Loading";

const transport = axios.create({
  withCredentials: true,
  credentials: "include"
});

export const AuthProvider = props => {
  const [state, setState] = useState({
    isLoading: true,
    webToken: null,
    credentials: null,
    isAuthenticated: false
  });

  useEffect(() => {
    transport.post("/api/auth/user").then(response => {
      const { data } = response;

      setState({
        webToken: data.token,
        credentials: data.credentials,
        isAuthenticated: data.signedIn ? true : false,
        isLoading: false
      });
    });
  }, []);

  const authenticate = credentials => {
    return transport.post("/api/auth/signin", credentials).then(response => {
      const { data } = response;

      if (data.success === true) {
        setState({
          webToken: data.token,
          credentials: data.credentials,
          isAuthenticated: true,
          isLoading: false
        });
        return true;
      } else {
        throw new Error("Invalid credentials");
      }
    });
  };

  const signout = () => {
    transport.post("/api/auth/signout").then(response => {
      setState(prevState => ({
        ...prevState,
        webToken: null,
        credentials: null,
        isAuthenticated: false
      }));
    });
  };

  const { isAuthenticated, isLoading, webToken, credentials } = state;

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, webToken, credentials, authenticate, signout }}
    >
      <Loading isLoading={isLoading}>{props.children}</Loading>
    </AuthContext.Provider>
  );
};
