import React from 'react';

import {
  Icon,
  Layout,
} from 'antd';

import Menu from './Menu';

const headerStyles = {
  background: "#fff",
  height: "46px",
  padding: 0,
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
};

const flexStyles = {
  display: "flex",
  alignItems: "center",
};

const Header = (props) => {
  const { user, season, collapsed, onCollapse } = props;

  return (
    <Layout.Header style={headerStyles}>
      <div style={flexStyles}>
        <Icon
          className="trigger"
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={onCollapse}
        />
      </div>

      <div style={flexStyles}>
        <Menu user={user} season={season} />
      </div>
    </Layout.Header>
  );
};

export default Header;
