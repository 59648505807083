import React, { useEffect, useState } from "react";

import { Button, Descriptions, Popconfirm, Table, Typography } from "antd";
import axios from "axios";
import PropTypes from "prop-types";

import { formatDate, formatDatetime } from "../shared/utils/dateTime";
import { formatPrice } from "../shared/utils/payments";

const sevisPrice = 40;

const PaymentsSummary = (props) => {
  const {
    price = 0,
    priceLeft,
    totalPaid,
    insurancePrice,
    onAddPayment,
    onDelete,
    payments,
  } = props;
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    axios.get("/api/currencies/USD").then((res) => {
      setCurrency(res.data);
    });
  }, []);

  const columns = [
    {
      title: "Дата",
      dataIndex: "date",
      render: (text, rec) => formatDate(text),
      width: 100,
    },
    {
      title: "Сума",
      dataIndex: "amountUSD",
      render: (text) => `${text}$`,
      width: 80,
    },
    {
      title: "Пояснение",
      dataIndex: "note",
      render: (text, rec) => (
        <>
          {text} {rec.currency === "BGN" && "(в лева)"}
        </>
      ),
    },
    {
      title: () => (
        <Button
          type="primary"
          size="small"
          // shape="circle"
          icon="dollar"
          onClick={onAddPayment}
          style={{ float: "right" }}
        ></Button>
      ),
      dataIndex: "",
      width: "16px",
      render: (text, rec) => (
        <Popconfirm
          title="Искате ли да изтриете?"
          onConfirm={() => onDelete(rec._id)}
        >
          <Button type="danger" size="small" icon="delete"></Button>
        </Popconfirm>
      ),
    },
  ];

  let totalPrice = price + sevisPrice; // include sevis
  if (insurancePrice) {
    totalPrice += insurancePrice; // insurance
  }

  const footer = () => {
    return (
      <>
        Общо платено: {totalPaid}$
        <br />
        <br />
        <Typography.Text type="danger">
          Оставаща сума в USD: {formatPrice(priceLeft, "$")} <br />
        </Typography.Text>
        {currency && (
          <>
            <Typography.Text type="danger">
              Оставаща сума в BGN:{" "}
              {formatPrice(priceLeft * currency.selling, "лв.")} (
              {formatPrice(priceLeft)} * {currency.selling})<br />
            </Typography.Text>
            <br />
            <p>
              <i>Курс на ПИБ към {formatDatetime(currency.updatedAt)}</i>
              <br />
              Купува: {currency.buying}
              <br />
              Продава: {currency.selling}
            </p>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="Цена на програмата">
          {price}$
        </Descriptions.Item>
        {insurancePrice && (
          <Descriptions.Item label="Застраховка">
            {insurancePrice}$
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Такса SEVIS">{sevisPrice}$</Descriptions.Item>
        <Descriptions.Item label="Общо">{totalPrice}$</Descriptions.Item>
      </Descriptions>
      <br />
      <Table
        bordered
        columns={columns}
        dataSource={payments}
        footer={footer}
        locale={{ emptyText: "Няма налични плащания" }}
        size="small"
        rowKey="_id"
        pagination={false}
      />
    </>
  );
};

PaymentsSummary.propTypes = {
  payments: PropTypes.array,
  price: PropTypes.number,
};

export default PaymentsSummary;
