import React, { useEffect, useReducer } from "react";

import { Badge, Calendar } from "antd";
import axios from "axios";
import moment from "moment";

import { AppointmentLegend, Loading, ViewAppointments } from "../components";
import { useSeason } from "../shared/hooks/useSeason";
import {
  formatDate,
  formatMonth,
  formatTime,
  isDateInFuture,
} from "../shared/utils/dateTime";

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return { ...state, isLoading: true };
    case "FETCH_SUCCESS":
      const appointments = formatAppointments(action.payload);
      return { ...state, isLoading: false, appointments };
    case "OPEN_DATE":
      return { ...state, selectedDate: action.payload };
    case "CLOSE_DATE":
      return { ...state, selectedDate: null };
    case "CHANGE_MODE":
      return { ...state, mode: action.payload };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const fetchAppointments = (dispatch, swtSeason, fetchStart = true) => {
  if (fetchStart) dispatch({ type: "FETCH_START" });
  axios.get(`/api/applications?swtSeason=${swtSeason}`).then((res) => {
    dispatch({ type: "FETCH_SUCCESS", payload: res.data });
  });
};

const formatAppointments = (appointments) => {
  return appointments
    .filter((a) => a.embassy)
    .map((a) => {
      return { applicationId: a._id, ...a, ...a.embassy, embassy: a.embassy };
    })
    .map((a) => {
      let status;
      if (a.visaApproved) {
        status = "success";
      } else {
        if (isDateInFuture(a.interviewDate)) {
          status = "warning";
        } else {
          status = "error";
        }
      }
      return { ...a, status };
    })
    .sort(
      (a, b) => moment(a.interviewDate).unix() - moment(b.interviewDate).unix()
    );
};

const Appointments = () => {
  const { swtSeason } = useSeason();

  const [{ isLoading, appointments, mode, selectedDate }, dispatch] =
    useReducer(fetchReducer, {
      isLoading: true,
      selectedDate: null,
      mode: "month",
      appointments: [],
    });

  useEffect(() => {
    fetchAppointments(dispatch, swtSeason);
  }, [swtSeason]);

  if (isLoading) {
    return <Loading isLoading />;
  }

  const getAppointmentsForDate = (value) => {
    return appointments.filter((a) => {
      return mode === "year"
        ? formatMonth(a.interviewDate) === formatMonth(value)
        : formatDate(a.interviewDate) === formatDate(value);
    });
  };

  const dateCellRender = (value) => {
    const dateAppointments = getAppointmentsForDate(value);

    if (!dateAppointments.length) {
      return "";
    }

    return (
      <ul className="events">
        {dateAppointments
          .sort(
            (a, b) =>
              moment(a.interviewDate).unix() - moment(b.interviewDate).unix()
          )
          .map((a) => (
            <li key={a._id}>
              <Badge
                status={a.status}
                text={`${formatTime(a.interviewDate)} – ${a.firstname} ${
                  a.lastname
                }`}
              />
            </li>
          ))}
      </ul>
    );
  };

  const monthCellRender = (value) => {
    const monthAppointments = appointments.filter(
      (a) =>
        formatDate(a.interviewDate, "MM/YYYY") === formatDate(value, "MM/YYYY")
    );
    const successfulAppointments = monthAppointments.filter(
      (a) => a.visaApproved
    );
    return (
      <div className="notes-month">
        <h3>{monthAppointments.length} интервюта</h3>
        <span>{successfulAppointments.length} взели виза</span>
      </div>
    );
  };

  return (
    <>
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        onPanelChange={(date, mode) => {
          dispatch({ type: "CHANGE_MODE", payload: mode });
        }}
        onSelect={(date) => dispatch({ type: "OPEN_DATE", payload: date })}
      />
      <ViewAppointments
        date={selectedDate}
        mode={mode}
        appointments={getAppointmentsForDate(selectedDate)}
        fetchAppointments={() => fetchAppointments(dispatch, swtSeason, false)}
        onClose={() => dispatch({ type: "CLOSE_DATE" })}
      />
      <AppointmentLegend />
    </>
  );
};

export default Appointments;
