import React, { useEffect, useState } from "react";

import { Button } from "antd";
import { Document, Page, pdfjs } from "react-pdf";

import { pdf } from "@react-pdf/renderer";

import workerURL from "../pdf.worker.min.data";

pdfjs.GlobalWorkerOptions.workerSrc = workerURL;

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ children, student }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const child = React.Children.only(children);

    pdf(child)
      .toBlob()
      .then((blob) => {
        setPdfUrl(URL.createObjectURL(blob));
      });
  }, [children]);

  return (
    <Document file={pdfUrl}>
      <Button
        href={pdfUrl}
        target="_blank"
        download={`Student_status_ENG-${student.lastname}_${student.firstname}.pdf`}
      >
        Download
      </Button>
      <Page renderMode="svg" pageNumber={1} />
    </Document>
  );
};

export { PDFViewer };
