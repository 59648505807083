import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  Icon,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

import { StudentRemoteSelect } from "../components/StudentRemoteSelect";
import { useSeason } from "../shared/hooks/useSeason";
import { priceToTextBulgarian } from "../shared/utils/prices";

const { Option } = Select;

const itemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 6,
    },
  },
};

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const AgreementForm = (props) => {
  const [students, setStudents] = useState();
  const [isFetching, setIsFetching] = useState(true);

  const { swtSeason } = useSeason();

  const { form } = props;
  const { getFieldDecorator } = form;

  const handleSubmit = (e, type) => {
    e.preventDefault();

    form.validateFields((err, { placementType, price }) => {
      if (!err) {
        const filename =
          type === "Agreement"
            ? `/templates/${placementType}.docx`
            : `/templates/${type}.docx`;
        loadFile(filename, function (error, content) {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          doc.setData({
            swtSeason: swtSeason,
            price: price,
            priceText: priceToTextBulgarian(price),
            priceLeft: price - 375,
            priceLeftText: priceToTextBulgarian(price - 375),
            priceLeftAfterDS2019: price - 375 - 300,
            priceLeftAfterDS2019Text: priceToTextBulgarian(price - 375 - 300),
            totalInsurance: price + 185, // 185 is US Embassy fee
            totalInsuranceText: priceToTextBulgarian(price + 185),
          });

          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              console.log("errorMessages", errorMessages);
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }); //Output the document using Data-URI
          saveAs(out, `${type}-${price}.docx`);
        });
      }
    });
  };

  useEffect(() => {
    axios.get(`/api/applications?swtSeason=${swtSeason}`).then((res) => {
      setStudents(res.data);
      setIsFetching(false);
    });
  }, [swtSeason]);

  const onChange = (value) => {
    const student = students.find((student) => student.id === value);
    form.setFieldsValue({
      price: student.price,
      placementType: student.placementType,
      insurance: student.insurance,
    });
  };

  if (isFetching) return <Spin />;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form layout="horizontal" spellCheck={false} {...itemLayout}>
          <Form.Item label="Студент">
            <StudentRemoteSelect students={students} onChange={onChange} />
          </Form.Item>

          <Form.Item label="Цена">
            {getFieldDecorator("price", {
              rules: [{ required: true, message: "Моля въведете цена" }],
            })(
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Цена в долари"
              />
            )}
          </Form.Item>

          <Form.Item label="Оферта">
            {getFieldDecorator("placementType", {
              rules: [
                { required: true, message: "Моля изберете вид програма" },
              ],
            })(
              <Select placeholder="Избери оферта">
                <Option value="Self">Self-placement</Option>
                <Option value="Full">Full-placement</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Застраховка" valuepropname="checked">
            {getFieldDecorator("insurance", { valuePropName: "checked" })(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              icon="file-word"
              onClick={(e) => handleSubmit(e, "Agreement")}
            >
              Договор
            </Button>

            <Button
              type="primary"
              icon="file-word"
              style={{ marginLeft: "20px " }}
              onClick={(e) => handleSubmit(e, "Insurance")}
            >
              Застраховка
            </Button>

            <Button
              type="primary"
              icon="file-word"
              style={{ marginLeft: "20px " }}
              onClick={(e) => handleSubmit(e, "Bank")}
            >
              Банка
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Form.create()(AgreementForm);
