import React, { useCallback } from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const { Search } = Input;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = (props) => {
  const { onAdd, onFilterChange, form, children } = props;
  const { getFieldDecorator } = form;

  const handleChange = (value) => {
    const { getFieldsValue } = form;
    const { search } = getFieldsValue();
    if (onFilterChange) onFilterChange(search);
  };

  const handleReset = (value) => {
    const { form } = props;
    const { getFieldsValue, setFieldsValue } = form;

    const fields = getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    setFieldsValue(fields);
    handleChange();
  };

  return (
    <Row gutter={24}>
      <Col {...ColProps} xl={{ span: 8 }} md={{ span: 8 }}>
        {getFieldDecorator("search", { initialValue: "" })(
          <Search
            autoComplete="off"
            placeholder={
              props.placeholder
                ? props.placeholder
                : `Търсене по име, егн или ел. поща`
            }
            spellCheck={false}
            onChange={useCallback(debounce(handleChange, 250), [])}
          />
        )}
      </Col>
      <Col
        {...TwoColProps}
        xl={{ span: 16 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
      >
        <Row type="flex" align="middle" justify="space-between">
          <div>
            <Button
              type="primary"
              className="margin-right"
              onClick={handleChange}
            >
              Търсене
            </Button>{" "}
            <Button onClick={handleReset}>Reset</Button>
          </div>
          <div>
            {children}
            {onAdd && (
              <Button type="ghost" onClick={onAdd}>
                Добави
              </Button>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  onAdd: PropTypes.func,
  form: PropTypes.object,
  filter: PropTypes.object,
  onFilterChange: PropTypes.func,
};

const WrappedFilter = Form.create({ name: "filter" })(Filter);

export default WrappedFilter;
