import React from "react";
import { Badge } from "antd";

function AppointmentLegend(props) {
  return (
    <div>
      <ul className="events">
        <li>
          <Badge status="warning" text="Предстояща виза" />
        </li>
        <li>
          <Badge status="success" text="Взета виза" />
        </li>
        <li>
          <Badge status="error" text="Отказана виза" />
        </li>
      </ul>
    </div>
  );
}

export default AppointmentLegend;
