import React from "react";

import { Badge, Drawer, List, Switch } from "antd";
import axios from "axios";
import PropTypes from "prop-types";

import { useScreenSize } from "../shared/hooks/useScreenSize";
import {
  formatDate,
  formatDatetimeBasic,
  formatMonth,
} from "../shared/utils/dateTime";

const ViewAppointments = ({
  date,
  mode,
  onClose,
  appointments,
  fetchAppointments,
}) => {
  const [screenSize, isMobile] = useScreenSize();

  const onChange = (item, visaApproved) => {
    axios
      .put(`/api/applications/${item.applicationId}`, {
        embassy: {
          ...item.embassy,
          visaApproved,
        },
      })
      .then((res) => {
        fetchAppointments();
      });
  };

  return (
    <Drawer
      title={`Списък визови интервюта`}
      width={isMobile ? screenSize : 540}
      placement="right"
      onClose={onClose}
      visible={date ? true : false}
    >
      <List
        dataSource={appointments}
        bordered
        header={`Дата: ${
          mode === "year" ? formatMonth(date) : formatDate(date)
        }`}
        renderItem={(item) => (
          <List.Item
            key={item._id}
            actions={[
              <Switch
                defaultChecked={item.visaApproved}
                onChange={(checked) => onChange(item, checked)}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={<Badge status={item.status} />}
              title={item.fullname}
              description={formatDatetimeBasic(item.interviewDate)}
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
};

ViewAppointments.propTypes = {
  date: PropTypes.object,
  appointments: PropTypes.array,
};

export default ViewAppointments;
