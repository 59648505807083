import React from "react";

import { Button, Col, Divider, Icon, Popconfirm, Row } from "antd";
import axios from "axios";

import { formatDate } from "../shared/utils/dateTime";
import BooleanIcon from "./BooleanIcon";
import DescriptionItem from "./DescriptionItem";

const pStyle = {
  fontSize: 16,
  fontWeight: "bold",
  lineHeight: "24px",
  display: "block",
  marginBottom: 16,
};

const ViewApplicationProfile = (props) => {
  const { application, onDelete } = props;
  const { employer = {} } = application;

  const deleteStudent = (id) => {
    axios.delete(`/api/applications/${id}`).then(() => {
      if (onDelete) onDelete();
    });
  };

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Ел. поща"
            content={
              <a href={`mailto:${application.email}`}>{application.email}</a>
            }
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Телефон"
            content={
              <a href={`tel:${application.phone}`}>{application.phone}</a>
            }
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="DS2019" content={application.ds2019} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={16}>
          <DescriptionItem
            title="Универистет"
            content={application.university?.name}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="Година" content={application.yearOfStudy} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Пол / спонсор"
            content={`${application.sex ? application.sex : ""} / ${
              application.sponsor
            }`}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="Цена" content={application.price} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="Оферта" content={application.offer} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="ЕГН" content={application.egn} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Дата на раждане"
            content={formatDate(application.dob)}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Място на раждане"
            content={application.city}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem title="Паспорт #" content={application.passport} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Дата на издаване"
            content={formatDate(application.passportValidFrom)}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title="Дата на валидност"
            content={formatDate(application.passportValidTill)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title={
              <>
                <Icon type="warning" /> Предишна виза/отказ
              </>
            }
            content={
              <>
                <BooleanIcon checked={application.priorVisa} />
                {" / "}
                <BooleanIcon checked={application.priorRefusal} />
              </>
            }
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title={
              <>
                <Icon type="bank" /> Кредит
              </>
            }
            content={<BooleanIcon checked={application.credit} />}
          />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <DescriptionItem
            title={
              <>
                <Icon type="fund" /> Застр. иска/сключена
              </>
            }
            content={
              <>
                <BooleanIcon checked={application.insurance} />
                {" / "}
                <BooleanIcon checked={application.insuranceEnrolled} />
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <DescriptionItem title="Notes" content={application.notes} />
        </Col>
      </Row>
      {!props.hideEmployer && (
        <>
          <Divider />
          <p style={pStyle}>Работодател</p>
          <Row>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem title="Работодател" content={employer.name} />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem title="Позиция" content={employer.position} />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem title="Телефон" content={employer.phone} />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem
                title="Начална дата"
                content={formatDate(application.startDate)}
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem
                title="Крайна дата"
                content={formatDate(application.endDate)}
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <DescriptionItem title="DS-2019" content={application.ds2019} />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <DescriptionItem title="Адрес" content={employer.address} />
            </Col>
          </Row>
        </>
      )}

      {!props.hideDelete && (
        <Popconfirm
          title={`Сигурни ли сте, че искате да изтриете ${application.fullname}?`}
          okText="Да"
          onConfirm={() => deleteStudent(application._id)}
        >
          <Button type="danger" size="small" icon="delete">
            изтрий
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default ViewApplicationProfile;
