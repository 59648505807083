import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

// TODO: Check if user is authenticated
const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const user = useContext(AuthContext);

  if (!user.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
