import React, { useState, useEffect } from "react";
import AddApplication from "./AddApplication";
import axios from "axios";

const EditApplication = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState();

  useEffect(() => {
    axios.get(`/api/applications/${props.applicationId}`).then(res => {
      setApplication(res.data);
      setIsLoading(false);
    });
  }, [props.applicationId]);

  return <AddApplication isLoading={isLoading} item={application} {...props} />;
};

export default EditApplication;
