import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

// class LoginForm extends React.Component {
const Logout = props => {
  const user = useContext(AuthContext);
  user.signout();
  return <Redirect to="/" />;
};

export default Logout;
