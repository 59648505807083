import React, { useEffect, useReducer } from "react";

import { Drawer } from "antd";
import axios from "axios";
import PropTypes from "prop-types";

import { AddPayment, Loading, PaymentsSummary } from "../components";
import { useScreenSize } from "../shared/hooks/useScreenSize";

const fetchApplication = (applicationId, dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios.get(`/api/applications/${applicationId}`).then((res) => {
    dispatch({ type: "FETCH_SUCCESS", payload: res.data });
  });
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        application: action.payload,
        isLoading: false,
      };
    case "SHOW_PAYMENT":
      return {
        ...state,
        showPayment: true,
      };
    case "HIDE_PAYMENT":
      return {
        ...state,
        showPayment: false,
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const ViewPayments = ({ applicationId, onClose }) => {
  const [screenSize, isMobile] = useScreenSize();
  const [{ application, showPayment, isLoading }, dispatch] = useReducer(
    fetchReducer,
    {
      application: {},
      isLoading: false,
      showPayment: false,
    }
  );

  useEffect(() => {
    fetchApplication(applicationId, dispatch);
  }, [applicationId]);

  const onCreate = (values) => {
    axios
      .post(`/api/applications/${application._id}/payments`, values)
      .then((res) => {
        dispatch({ type: "HIDE_PAYMENT" });
        fetchApplication(applicationId, dispatch);
      });
  };

  const onDelete = (id) => {
    axios
      .delete(`/api/applications/${application._id}/payments/${id}`)
      .then((res) => {
        fetchApplication(applicationId, dispatch);
      });
  };

  return (
    <Drawer
      title={application.fullname}
      width={isMobile ? screenSize : 540}
      placement="right"
      onClose={onClose}
      visible={true}
    >
      <Loading isLoading={isLoading}>
        {showPayment && (
          <AddPayment
            onClose={() => dispatch({ type: "HIDE_PAYMENT" })}
            applicationId={application._id}
            onSubmit={onCreate}
          />
        )}

        {application && (
          <PaymentsSummary
            price={application.price}
            payments={application.payments}
            priceLeft={application.priceLeft}
            totalPaid={application.totalPaid}
            insurancePrice={application.insurancePrice}
            onAddPayment={() => dispatch({ type: "SHOW_PAYMENT" })}
            onDelete={onDelete}
          />
        )}
      </Loading>
    </Drawer>
  );
};

ViewPayments.propTypes = {
  applicationId: PropTypes.string,
};

export default ViewPayments;
