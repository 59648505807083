export const priceToTextBulgarian = (price) => {
  const units = [
    "",
    "едно",
    "две",
    "три",
    "четири",
    "пет",
    "шест",
    "седем",
    "осем",
    "девет",
  ];
  const teens = [
    "",
    "единадесет",
    "дванадесет",
    "тринадесет",
    "четиринадесет",
    "петнадесет",
    "шестнадесет",
    "седемнадесет",
    "осемнадесет",
    "деветнадесет",
  ];
  const tens = [
    "",
    "десет",
    "двадесет",
    "тридесет",
    "четиридесет",
    "петдесет",
    "шестдесет",
    "седемдесет",
    "осемдесет",
    "деветдесет",
  ];
  const hundreds = [
    "",
    "сто",
    "двеста",
    "триста",
    "четиристотин",
    "петстотин",
    "шестстотин",
    "седемстотин",
    "осемстотин",
    "деветстотин",
  ];
  const scales = ["", "хиляди"];

  function convertLessThanOneThousand(num) {
    if (num === 0) {
      return "";
    } else if (num < 10) {
      return units[num] + " ";
    } else if (num < 20) {
      return teens[num - 10] + " ";
    } else if (num < 100) {
      return (
        tens[Math.floor(num / 10)] + " " + convertLessThanOneThousand(num % 10)
      );
    } else {
      return (
        hundreds[Math.floor(num / 100)] +
        " " +
        convertLessThanOneThousand(num % 100)
      );
    }
  }

  function convertPriceToWords(price) {
    if (price === 0) {
      return "нула";
    }

    let result = "";
    let i = 0;

    while (price > 0) {
      if (price % 1000 !== 0) {
        result =
          convertLessThanOneThousand(price % 1000) + scales[i] + " " + result;
      }
      price = Math.floor(price / 1000);
      i++;
    }

    return result.trim();
  }

  const words = convertPriceToWords(price)
    .replace("едно хиляди", "хиляда")
    .split(" ");

  if (words.length > 1) {
    // Insert 'и' before the last number
    words.splice(-1, 0, "и");
  }
  return words.join(" ");
};
