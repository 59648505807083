import React from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  message,
  Upload,
  Icon,
} from "antd";

const { Dragger } = Upload;

const uploadProps = {
  name: "file",
  action: "/api/upload",
  accept: ".png,.jpg,jpeg",
  data: {
    applicationId: "logos",
  },
  onChange(info) {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const AddUniversityForm = (props) => {
  const { form, item = {}, onSubmit, onClose } = props;
  const { getFieldDecorator } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Drawer
      title={props.item ? "Редактирай университет" : "Добави университет"}
      width={320}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Име на университет">
              {getFieldDecorator("name", {
                initialValue: item.name,
                rules: [{ required: true, message: "Задължително поле" }],
              })(
                <Input
                  style={{ width: "100%" }}
                  placeholder="Technical University"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Град">
              {getFieldDecorator("city", {
                initialValue: item.city,
                rules: [{ required: true, message: "Задължително поле" }],
              })(<Input style={{ width: "100%" }} placeholder="Sofia" />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Лого">
              {getFieldDecorator("logo", {
                initialValue: item.logo
                  ? [
                      {
                        uid: 1,
                        url: `/api/upload/logos/${item.logo.name}`,
                        ...item.logo,
                      },
                    ]
                  : [],
                valuePropName: "fileList",
                getValueFromEvent: (e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  if (e.fileList.length > 1) {
                    e.fileList.shift();
                  }
                  return e && e.fileList;
                },
              })(
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Натисни или пусни файл за да качиш
                  </p>
                </Dragger>
              )}
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            Запази
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default Form.create()(AddUniversityForm);
