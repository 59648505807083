import moment from "moment";

export const formatDate = (date, format = "DD/MM/YYYY") => {
  return date ? moment(date).format(format) : date;
};

export const formatTime = (date, format = "h:mm A") => {
  return formatDatetime(date, format);
};

export const formatDatetime = (date, format = "DD/MM/YYYY h:mm A") => {
  return date ? moment(date).format(format) : date;
};

export const formatDatetimeBasic = (date, format = "DD/MM @ h:mm A") => {
  return formatDatetime(date, format);
};

export const formatMonth = (date, format = "MM/YYYY") => {
  return date ? moment(date).format(format) : date;
};

export const momentDatetime = (date) => {
  return date ? moment(date) : date;
};

export const isDatePastNow = (date) => {
  return date ? moment().diff(date) >= 0 : date;
};

export const isDateInFuture = (date) => {
  return !isDatePastNow(date);
};

export const formatDateTimeForAPI = (date) => {
  return date ? moment(date).utc().format() : date;
};
