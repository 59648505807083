import React from "react";
import { Spin, Icon } from "antd";

const loadingContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh"
};

const Loading = props => {
  const { children = "" } = props;
  const spinner = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  if (props.isLoading) {
    return (
      <div style={loadingContainerStyle}>
        <Spin indicator={spinner} size="large" />{" "}
      </div>
    );
  }
  return children;
};

export default Loading;
