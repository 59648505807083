import React from "react";
import { Icon } from "antd";

const BooleanIcon = ({ checked, colored = false }) => {
  const iconType = checked ? "check" : "close";
  const colors = { check: "#52c41a", close: "#eb2f96" };

  if (colored) {
    return (
      <Icon
        type={`${iconType}-circle`}
        theme="twoTone"
        twoToneColor={colors[iconType]}
      />
    );
  } else {
    return <Icon type={iconType} />;
  }
};

export default BooleanIcon;
